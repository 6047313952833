'use client';
import { Show } from '@chakra-ui/media-query';
import cn from 'classnames';
import Image from 'next/image';
import React, { useRef, useState } from 'react';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import LinkCustom from '@/app/_components/link/LinkCustom';
import { ReactComponent as ArrowBackwardCircle } from '@/app/_icons/ArrowBackwardCircleOutline.svg';
import { ReactComponent as ArrowForwardCircle } from '@/app/_icons/ArrowForwardCircleOutline.svg';
import { assetsUri } from '@/services/api.service';
import { ImageTransformationKeys } from '@/types/files/files.types';
import { FeaturedItem } from '@/types/home/home.types';
import { NewCarOverview } from '@/types/new-cars/new-cars.types';
import { directusImageLoader } from '@/utils/loaders';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import './NewVehiclePromotion.scss';

type Props = {
  imageKeys: ImageTransformationKeys;
  isDealershipPage?: boolean;
  featuredItems: FeaturedItem[];
  newCarsWithOverview?: NewCarOverview[];
};

const NewVehiclePromotion = ({
  imageKeys,
  featuredItems,
  isDealershipPage = false,
}: Props) => {
  const swiperRef: any = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const CustomNavigation = () => {
    return (
      <div className="swiper-navigation">
        <button
          onClick={goPrev}
          className="swiper-button-prev-1"
          role="button"
          name="Prev"
        >
          <ArrowBackwardCircle />
        </button>
        <button
          onClick={goNext}
          className="swiper-button-next-1"
          role="button"
          name="Next"
        >
          <ArrowForwardCircle />
        </button>
      </div>
    );
  };

  const renderItem = (item: FeaturedItem) => {
    return (
      <div className="cardContent">
        <div className="label">{item?.heading || 'ARRIVE HAPPY'}</div>
        <div className="modalYear">{item?.subheading}</div>
        <LinkCustom
          className="btnOutlineWhite"
          role="link"
          href={item?.call_to_action_url}
        >
          {item?.call_to_action_text}
        </LinkCustom>
      </div>
    );
  };

  return (
    <>
      <div className="featureSectionWrapper">
        <Swiper
          ref={swiperRef}
          slidesPerView={3}
          spaceBetween={40}
          modules={[Pagination, Navigation]}
          pagination={{
            el: '.swiper-pagination',
            type: 'fraction',
            formatFractionCurrent: function (number) {
              return number < 10 ? '0' + number : number.toString();
            },
          }}
          navigation={{
            nextEl: '.swiper-button-next-1',
            prevEl: '.swiper-button-prev-1',
          }}
          className="featureSlider"
          onSlideChange={(swiper) => {
            setCurrentPage(swiper.realIndex + 1);
          }}
          onSwiper={(swiper) => {
            setTotalPages(swiper.slides.length);
          }}
          breakpoints={{
            0: {
              slidesPerView: 1,
              spaceBetween: 50,
            },
            576: {
              slidesPerView: 1,
              spaceBetween: 50,
            },
            640: {
              slidesPerView: 1,
              spaceBetween: 50,
            },
            641: {
              slidesPerView: 2,
              spaceBetween: 15,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
        >
          {featuredItems &&
            featuredItems?.map((item, index) => (
              <SwiperSlide key={index}>
                <Show breakpoint="(min-width: 576px)">
                  <div className="featureSectionCard">
                    <Image
                      src={`${assetsUri}/${item.image}?key=${imageKeys.desktopKey}`}
                      loader={directusImageLoader}
                      className="featureSectionCardImage"
                      width={400}
                      height={476}
                      alt=""
                    />
                    {renderItem(item)}
                  </div>
                </Show>
                <Show breakpoint="(max-width: 575px)">
                  <div className="featureSectionCard">
                    <Image
                      src={`${assetsUri}/${item.image}?key=${imageKeys.mobileKey}`}
                      loader={directusImageLoader}
                      className="featureSectionCardImage"
                      width={400}
                      height={476}
                      alt=""
                    />
                    {renderItem(item)}
                  </div>
                </Show>
              </SwiperSlide>
            ))}
        </Swiper>
        <div
          className={cn(
            'featureSwiperControls',
            isDealershipPage && 'showNavigation',
          )}
        >
          <div className="swiper-pagination">
            <span className="current-page">{currentPage}</span>
            <span className="separator">/</span>
            <span className="total-pages">{totalPages}</span>
          </div>

          <CustomNavigation />
        </div>
      </div>
    </>
  );
};

export default NewVehiclePromotion;
